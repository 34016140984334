import React, { useState } from "react";
import "./projects.css";

function Projects() {
    return (
        <div className="projects">
            <h1>My Projects</h1>
            <div className="project-item">
                <h2>Home Lab Server Environment</h2>
                <p>This project encompasses a little bit of everything. What started out as a cheap way to gain some digital freedom and mess around with new technologies/software has turned into an in-depth 
                    server environment with multiple virtual and physical machines, docker services and applications running. The environment is built using Proxmox as the hypervisor,
                    with VM's that run a NAS, a Cloud Server for docker services, a Windows Server for Active Directory and some basic DNS, and a Linux server for development and CI/CD pipelines.
                    Another system that runs a suite of open-source security tools such as a SIEM, a SOAR, and OSSEC, and other offense and defense tools to 
                    play around with or secure my environment. The environment is also used for simulating a production environment with written policies, and documentation .
                </p>
                <a href="">View Project on GitHUb</a>
            </div>
            <div className="project-item">
                <h2>Encryption Schemes</h2>
                <p>This project is a bit more simple, its basic implementations of different encryption schemes such as symmetric,
                    PKI, hashing, digital signatures, homomorphic encryption, and more. The project is built using Python and is a work in progress.
                    The goal is to document and implement the most common encryption schemes using a cryptography library and document the underlying processes and 
                    math behind each scheme and its use cases.</p>
                <a href="">View Project on GitHub</a>
            </div>
            <div className="project-item">
                <h2>SDN Network Tool</h2>
                <p>Working on building an application to control a simulated network. This SDN tool is trying to allow for things like 
                    custom firewall rules, QoS, dynamic routing and path optimization, and more. The tool is built using custom Mininet networks with many endpoints, routers, switches, and Python
                    with the Ryu SDN controller. The goal is to have a simple to use GUI interface that allows for easy network management. The 
                    project is still in the early stages. 
                </p>
                <a href="">View Project on GitHub</a>
            </div>
            <div className="project-item">
                <h2>ZTNA Web App</h2>
                <p>Developing a basic system to enforce strict access controls and ensure secure network access.
                    The system uses JWT (JSON Web Tokens) for user authentication and device trust checks,
                    allowing users to securely connect only if their device meets predefined security criteria.
                    Implemented a Flask web server that issues tokens based on valid user credentials and verifies
                    tokens with device information for each request.</p>
                <a href="">View Project on GitHub</a>
            </div>
            <div className="project-item">
                <h2>General Projects</h2>
                <p>This is some other stuff I have done using relational and non-relational databases utilizing SQL scripts and JSON. As well as
                    some tinkering with operating systems using C, and more general programming projects like data structures and
                    algorithms using C++ and C#. It also includes this website which uses React and other web technologies.</p>
                <a href="">View Projects on GitHub</a>
            </div>
        </div>
    );
}

export default Projects;