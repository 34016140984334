import React from "react";
import { Link } from "react-router-dom";
import "./home.css";

function Home() {
    return (
    <div className="hero">
        <h1>Grayson Morris</h1>
        <p>I'm Grayson. I recently graduated from Colorado State University with a bachelors of Science in 
            Computer Science. I currently work as an IT support technician, and have an interest in cybersecurity, and
            network engineering. I built this website to showcase my projects, resume, and learn some more about underlying 
            web/internet technologies like DNS, React, and OWASP. Feel free to explore my site, or even just download my resume
            for the quick version of my qualifications.</p>
        <div className="quick-action-buttons">
            <Link to="/Resume" className="btn btn-primary">View My Resume</Link>
            <Link to="/Projects" className="btn btn-secondary">Explore My Projects</Link>
        </div>
    </div>
    );
}

export default Home;